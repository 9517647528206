import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading || _vm.loadingAgregado}},[_c(VToolbar,{attrs:{"dense":"","dark":"","flat":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Agregar examen")]),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","icon":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pt-4"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","md":"7"}},[_c(VSelect,{attrs:{"items":_vm.areas,"item-text":"nombre","item-value":"_id","outlined":"","dense":"","label":"Área de exámenes","no-data-text":"Sin áreas registradas","disabled":_vm.loading || _vm.loadingAgregado},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})],1),_c(VCol,{attrs:{"cols":"12","md":"5"}},[_c(VTextField,{attrs:{"label":"Buscar","outlined":"","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"cols":"12"}},[(_vm.loading)?_c('loading-examenes'):(!_vm.examenes.length)?_c('span',[_vm._v("No se han agregado exámenes.")]):_c('div',[_c(VCard,{attrs:{"tile":"","flat":"","color":"#eceff1"}},[(_vm.loadingAgregado)?_c(VProgressLinear,{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_vm._l((_vm.examenesFiltrados),function(examen){return _c('examen-item',{key:examen._id,attrs:{"examen":examen,"disabled":_vm.loadingAgregado},on:{"clickAction":function($event){return _vm.agregar(examen._id)}}})})],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }