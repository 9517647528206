<template>
  <div>
    <v-skeleton-loader
      v-for="index in 3"
      :key="index"
      type="list-item-avatar"
      class="my-3"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {};
</script>
