import {
  getServerRequest,
  putServerRequest,
  patchServerRequest,
  deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "../../../../store/store";

const httpURL = store.getters.httpURL;

export const getExamenAreas = async () => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/areas`;
  return await getServerRequest(url, config);
};

export const crearAreaExamenes = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/areas`;

  return await putServerRequest(url, data, config);
};

export const modificarAreaExamenes = async (idArea, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/area/${idArea}`;

  return await patchServerRequest(url, data, config);
};

export const getExamenesByArea = async (idArea) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/byArea/${idArea}`;
  return await getServerRequest(url, config);
};

export const getExamenesCompartidos = async () => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/compartidos`;
  return await getServerRequest(url, config);
};

export const crearExamen = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes`;

  return await putServerRequest(url, data, config);
};

export const modificarExamen = async (idExamen, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}`;

  return await patchServerRequest(url, data, config);
};

export const clonarExamen = async (idExamen, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/clon`;

  return await patchServerRequest(url, data, config);
};

export const getExamen = async (idExamen) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}`;
  return await getServerRequest(url, config);
};

export const getSeccion = async (idExamen, idSeccion) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/seccion/${idSeccion}`;
  return await getServerRequest(url, config);
};

export const crearSeccion = async (data, idExamen) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/seccion`;

  return await putServerRequest(url, data, config);
};

export const modificarSeccion = async ({ idSeccion, idExamen, data }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/seccion/${idSeccion}`;

  return await patchServerRequest(url, data, config);
};

export const crearPagina = async ({ idExamen, data }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/pagina`;

  return await putServerRequest(url, data, config);
};

export const getPagina = async ({ idExamen, idPagina }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/pagina/${idPagina}`;
  return await getServerRequest(url, config);
};

export const modificarPagina = async ({ idPagina, idExamen, data }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/pagina/${idPagina}`;

  return await patchServerRequest(url, data, config);
};

export async function obtenerUsuariosService(tipo, idExamen) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/usuarios/${tipo}/${idExamen}`;
  return await getServerRequest(url, config);
}

export async function agregarUsuarioService(tipo, idPlantilla, idUsuario) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const data = { idUsuario };
  const url = `${httpURL}/examenes/usuarios/${tipo}/${idPlantilla}`;
  return await putServerRequest(url, data, config);
}

export const quitarExamenCompartido = async (idExamen) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/usuario/${idExamen}`;
  return await deleteServerRequest(url, config);
};

export const eliminarExamen = async (idExamen) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}`;
  return await deleteServerRequest(url, config);
};
