<template>
  <v-dialog v-model="mostrar" persistent max-width="700">
    <v-card :loading="loading || loadingAgregado">
      <v-toolbar dense dark flat color="primary">
        <v-toolbar-title>Agregar examen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn fab icon @click="$emit('cancelar')">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-row dense>
          <v-col cols="12" md="7">
            <v-select
              v-model="area"
              :items="areas"
              item-text="nombre"
              item-value="_id"
              outlined
              dense
              label="Área de exámenes"
              no-data-text="Sin áreas registradas"
              :disabled="loading || loadingAgregado"
            ></v-select>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="search"
              label="Buscar"
              outlined
              dense
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <loading-examenes v-if="loading"></loading-examenes>
            <span v-else-if="!examenes.length"
              >No se han agregado exámenes.</span
            >
            <div v-else>
              <v-card tile flat color="#eceff1">
                <v-progress-linear
                  v-if="loadingAgregado"
                  color="primary"
                  indeterminate
                ></v-progress-linear>
                <examen-item
                  v-for="examen in examenesFiltrados"
                  :key="examen._id"
                  :examen="examen"
                  :disabled="loadingAgregado"
                  @clickAction="agregar(examen._id)"
                ></examen-item>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getExamenAreas,
  getExamenesByArea,
} from "@/components/globals/template/examenes/examenes.service";
import { agregarExamenAAplicacion } from "./examenAplicacion.service";

import loadingExamenes from "@/components/globals/template/examenes/loadingExamenes.vue";
import examenItem from "./examenItem.vue";

export default {
  components: {
    "loading-examenes": loadingExamenes,
    "examen-item": examenItem,
  },

  props: {
    mostrar: { type: Boolean, default: false },
  },

  data: () => ({
    loading: true,
    loadingAgregado: false,
    areas: [],
    examenes: [],
    area: "",
    search: "",
  }),

  computed: {
    examenesFiltrados() {
      if (this.search)
        return this.examenes.filter((e) =>
          e.nombre.toUpperCase().includes(this.search.toUpperCase())
        );
      return this.examenes;
    },

    idCurso() {
      return this.$route.params.idCurso;
    },

    idModulo() {
      return this.$route.params.idModulo;
    },

    idAplicacion() {
      return this.$route.params.idAplicacion;
    },
  },

  watch: {
    area(value) {
      if (value) this.getExamenes();
    },
  },

  mounted() {
    this.getAreas();
  },

  methods: {
    async getAreas() {
      this.loading = true;

      try {
        const serverResponse = await getExamenAreas();
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.areas = serverResponse.areas;
          this.area = this.areas.length ? this.areas[0]._id : null;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async getExamenes() {
      this.loading = true;

      try {
        const serverResponse = await getExamenesByArea(this.area);
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.examenes = serverResponse.examenes;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async agregar(examen) {
      this.loadingAgregado = true;

      try {
        const inputData = { examen };
        const serverResponse = await agregarExamenAAplicacion({
          idCurso: this.idCurso,
          idModulo: this.idModulo,
          idAplicacion: this.idAplicacion,
          data: inputData,
        });
        this.loadingAgregado = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("examenAgregado", serverResponse.examen);
      } catch (error) {
        this.loadingAgregado = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
